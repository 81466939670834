/* BACKGROUND -----------------------------------------------------------------------------------*/
.body {
  font-family: "Roboto", sans-serif;
  /* box-shadow: inset 0px 0px 0px 1px #cc02ff; */
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.background {
  /* background-image: url("https://www.constructorabolivar.com/sites/all/themes/constructora/assets/fondos/fondo-proyectos.svg"); */
  z-index: -5;
  margin: 0;
  padding: 0;
  position: fixed;
  transform: translate(-50%, 0%);
  left: -5%;
}

/* NAV BAR -----------------------------------------------------------------------------------*/
.navBar {
  z-index: 98;
  position: fixed;
  width: 100%;
  background-color: #008c45;
  height: 65px;
  padding: 5px 0px;
  box-shadow: 0px 3px 5px #808080;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}
.navBar-Greeting {
  color: white;
  font-size: large;
  font-weight: bold;
  white-space: pre-wrap;
}
@media (max-width: 600px) {
  .navBar-Greeting {
    display: none;
  }
}

.clientLogo {
  z-index: 999;
  position: fixed;
  height: 65px;
  left: 10%;
  padding: 5px 0px;
}

.signout {
  z-index: 999;
  position: fixed;
  right: 10%;
  height: 65px;
  padding: 5px;
}

#g_id_signout {
  height: 40px;
  gap: 10px;
  border-radius: 25px 15px 15px 25px;
  padding: 0px 25px 0px 0px;
  box-shadow: 0px 2px 5px #165e2e;
  border: none;
  cursor: pointer;
}

/* FOOTER -----------------------------------------------------------------------------------*/
.footer {
  z-index: 98;
  width: 100%;
  height: 35px;
  position: fixed;
  bottom: 0%;
  gap: 10px;
  background-color: rgb(53, 53, 53);
}

/* HOME -----------------------------------------------------------------------------------*/
#homeContainer {
  flex-direction: row;
  gap: 40px;
}
.homeContainer-Logo {
  width: 180px;
}
@media (max-width: 670px) {
  #homeContainer {
    gap: 20px;
    flex-direction: column;
  }
  .homeContainer-Logo {
    height: 150px;
    width: auto;
  }
}

.homeContainer-description {
  max-width: 300px;
  text-align: justify;
  padding: 0px 20px;
}
@media (max-width: 529px) {
  .homeContainer-description {
    text-align: center;
  }
}

.homeContainer-description > * {
  margin: 0;
  width: 100%;
  padding-bottom: 20px;
}

.divider {
  width: 1px;
  height: 100px;
  background-color: #2c2c2c;
}
@media (max-width: 670px) {
  .divider {
    display: none;
  }
}
