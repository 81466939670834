/* APP -----------------------------------------------------------------------------------*/
.App {
  text-align: center;
  padding-top: "20px";
  font-family: "Roboto";
  /* font-size: 22px; */
}

.myAppContainer {
  /* box-shadow: inset 0px 0px 0px 2px #cc02ff; */
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  min-height: 100vh;
  vertical-align: middle;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0;
  flex-flow: column wrap;
  /* padding-top: 100px; */
}

.myAppContainer > div {
  /* box-shadow: inset 0px 0px 0px 1px #cc02ff; */
  width: 100%;
}

.goToAction {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.goToActionBtn {
  min-width: 250px;
  max-height: 45px;
  margin-top: 30px;
  padding-right: 40px;
  border-radius: 20px;
  border: transparent;
  color: white;
  background-color: #008d3d;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: medium;
}
.goToActionIcon {
  height: 70px;
  vertical-align: middle;
  padding: 0px 0px 0px 10px;
  transform: translateX(-25px);
}

.planoTipoSlider {
  text-align: center;
  margin: 20px;
}

.myDropdown {
  display: block;
  margin: auto;
  padding: 10px 20px 10px 20px;
  background-color: transparent;
  max-width: 750px;
}

.dropdownTittle {
  z-index: 100;
  text-align: left;
  padding-left: 5%;
  font-size: 14px;
  font-weight: bold;
}

.imageGrid {
  padding: 0px 25px 100px 25px;
  /* paddingBottom: 100px; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  align-items: flex-start;
  max-width: 900px;
  margin: auto;
}

.selectedFicha {
  box-shadow: inset 0px 0px 0px 1px #cc02ff;
  background-color: white;
  height: 40px;
  max-width: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 5px #a7a7a7;
  border-radius: 20px;
}

.debug {
  z-index: 99;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 30px;
  width: 100px;
}
.debug > * {
  cursor: pointer;
  width: 100%;
  height: 100%;
  color: transparent;
  background-color: transparent;
  border: transparent;
}
